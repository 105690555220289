<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>岗位权限管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card>
      <!-- 添加按钮 -->
      <el-row>
        <el-col>
          <el-button icon="el-icon-plus" type="primary">添加</el-button>
        </el-col>
      </el-row>
      <!-- 岗位权限列表 -->
      <div v-if="roleName == '超级管理员'">
        <el-table :data="roleList" border stripe>
          <!-- 展开列 -->
          <el-table-column type="expand">
            <template v-slot="scope">
              <el-row :class="['bdbottom', i1===0?'bdtop':'','vcenter']" v-for="(item1, i1) in scope.row.children" :key="item1.id">
                <!-- 一级权限 -->
                <el-col :span="5">
                  <el-tag icon="el-icon-caret-right" closable @close="removeById(scope.row,item1.id)" >{{item1.authName}}</el-tag>
                  <i class="el-icon-caret-right"></i>
                </el-col>
                <!-- 二级 -->
                <el-col :span="19">
                  <el-row :class="[i2===0?'':'bdtop','vcenter']" v-for="(item2, i2) in item1.children" :key="item2.id">
                    <el-col :span="6">
                      <el-tag type="success" closable @close="removeById(scope.row,item2.id)" >{{item2.authName}}</el-tag>
                      <i class="el-icon-caret-right"></i>
                    </el-col>
                    <el-col :span="18">
                      <el-tag v-for="item3 in item2.children" :key="item3.id" type="warning" closable @close="removeById(scope.row,item3.id)" >{{item3.authName}}</el-tag>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="岗位名称" prop="roleName"></el-table-column>
          <el-table-column label="操作" width="300px">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-setting" size="mini" @click="SetDialog(scope.row)">查看权限</el-button>
              <el-button type="success" icon="el-icon-edit" size="mini">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 非超级管理员 -->
      <div v-if="roleName != '超级管理员'">
         暂无权限
      </div>
    </el-card>

    <!-- 分配权限 对话框 -->
    <el-dialog title="查看权限" v-model="SetDialogVisible" width="50%" @close="SetDialogClosed">
      <!-- 树形控件 -->
      <el-tree
        :data="rightsList"
        :props="treeProps"
        show-checkbox
        node-key="id"
        default-expand-all
        highlight-current
        check-on-click-node
        @node-click="nodeClick"
        :default-checked-keys="defKeys"
      ></el-tree>

      <!-- 底部 -->
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="SetDialogVisible = false">确定</el-button>
          <el-button @click="removePowerById">删除该权限</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      romoveRole:{},
      activeRow: "",
      comroleList:[],
      roleList: [],
      rightsList: [],
      SetDialogVisible: false,
      // 树形控件的属性绑定对象
      treeProps: {
        label: "description",
        children: "children"
      },
      // 默认选中的节点id值
      defKeys: [],
      roleName:""
    };
  },
  mounted() {
    this.roleName = window.sessionStorage.getItem('roleName')
    if(this.roleName == '超级管理员'){this.getRoleList();}
    // this.getComRole()

  },
  methods: {
    async getComRole() {
      let curCompanyId = parseInt(window.sessionStorage.getItem("curCompanyId"));
      const { data:res } = await this.$http.get("user/getItemAdmin",{params:{curCompanyId}});
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error("获取列表失败");
      }
      this.comroleList = res.data
    },

    async removePowerById(){
      const confirmRes = await this.$confirm("确认删除该权限？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => console.log(err));
      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }
      console.log(this.romoveRole)
      const {data:res} = await this.$http.post("role/deletePermission",this.romoveRole)
      if(res.code != 200) {
        return this.$message.error(res.msg);
      }
      window.reload();
    },

    // 获取岗位列表(超级管理员)
    async getRoleList() {
      let curCompanyId=parseInt(window.sessionStorage.getItem("curCompanyId"));
      const { data: res } = await this.$http.get("role/getRoleWithPermission",{params:{curCompanyId}});
      if (res.code !== 200) {
        return this.$message.error("获取列表失败1");
      }
      this.roleList = res.data;
    },
    async removeById(role, rightId) {
      const confirmRes = await this.$confirm("确认删除该权限？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => console.log(err));
      if (confirmRes !== "confirm") {
        return this.$message.info("取消删除");
      }
      // const { data: res } = await this.$http.delete(
      //   `roles/${role.id}/rights/${rightId}`
      // );
      // if (res.meta.status !== 200) {
      //   return this.$message.error("删除失败");
      // }
      // role.children = res.data;
    },
    async SetDialog(role) {
      console.log("岗位id:",role.roleId)
      this.romoveRole.roleId = parseInt(role.roleId)
      let roleId = role.roleId
      const { data: res } = await this.$http.get("role/getPermissionByRole",{params:{roleId}});
      if (res.code !== 200) {
        return this.$message.error("获取权限数据失败");
      }
      this.rightsList = res.data;
      this.getLeafKeys(role,this.defKeys)
      this.SetDialogVisible = true;
    },
    nodeClick(data,node,obj) {
      var chirdenNode = data.children
      if(node.checked){
        if(chirdenNode.length>0) {
          var secondCnode = chirdenNode[0].children;
          if(secondCnode.length > 0) {
            var thirdNode = secondCnode[0].children
            if(thirdNode.length == 0) {
              this.romoveRole.pid = secondCnode[0].pid;
            }
          }else if(secondCnode.length == 0) {
            this.romoveRole.pid = chirdenNode[0].pid;
            console.log(this.romoveRole)
          }
        }

      }
    },
    // 通过递归方式，获取岗位下所有三级权限id，保存在defKeys
    getLeafKeys(node,arr){
        if(!node.children){
            return arr.push(node.id)
        }
        node.children.forEach(item => {
            this.getLeafKeys(item,arr);
        });
    },
    SetDialogClosed(){
        this.defKeys=[]
    }
  }
};
</script>

<style lang="less" scoped>
.el-tag {
  margin: 7px;
}
.bdtop {
  border-top: 1px solid #eee;
}
.bdbottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
</style>
